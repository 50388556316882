<template>
    <span class="chip">
        <span class="point big" :style="'background-color:'+item"></span>
    </span>
</template>
<script>
export default {

  data () {
    return {
      item: ''
    }
   },
   beforeMount() {
       this.item = this.params.value;
   }
};
</script>
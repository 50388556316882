<template>
    <vs-sidebar
        position-right
        v-model="active"
        parent="body" default-index="55555551"
        class="items-no-padding"
        >
        <div v-if="isLoadingCompany || isUpdatingCompany || isDeletingCompany" class="h-100">
            <div class="text-center flex-center h-100">
                <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Chargement...</span>
                </div>
                <br />
                Chargement des détails {{companyType.label ? 'du '+ companyType.label.toLowerCase() : ''}}...
            </div>
        </div> 

        <div v-else-if="company" class="h-full">
            <div class="sidebar-header py-1 px-2">
                <div class="d-flex align-items-center">
                  <v-swatches
                    v-if="!archived"
                    v-model="company.color"
                    show-fallback
                    popover-x="right"
                    id="color"
                    class="mr-1"
                    :show-border="false"
                    :swatch-size="32"
                    :trigger-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                    :swatch-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                    :wrapper-style="{ paddingLeft: '8px', paddingRight: '0px' }"
                    style="height: 32px;"
                    @input="changeCompanyColor"
                  ></v-swatches>
                  <div>
                    <h3>{{company.name ? company.name : company.firstName+' '+company.lastName }}</h3>
                    <small v-if="companyType.label">{{ (companyType.label[companyType.label.length-1] =='s' ? companyType.label.substring(0, companyType.label.length - 1) : companyType.label) }}</small>
                  </div>
                </div>
                <feather-icon icon="XIcon" size="24" @click.stop="active =!active" class="close-icon cursor-pointer"></feather-icon>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-action py-05 px-2">
                <div class="d-flex align-items-center">

                    <b-button
                      v-if="archived"
                      variant="warning"
                      class="btn-icon"
                      @click="restoreCompanyLocal(company.id, company.name)"
                    >
                        <feather-icon icon="ArrowDownIcon" /> Restaurer
                    </b-button>
                    <b-button
                      v-else
                      class="btn-icon"
                      variant="primary"
                      @click="editCompany(company.id)"
                      :disabled="archived"
                    >
                        <feather-icon icon="EditIcon" /> Modifier
                    </b-button>
                      <!-- <vs-dropdown class="cursor-pointer chip">
                        <span class="point" :class="'bg-'+company.status.class"></span>
                        <span class="label" :class="'text-'+company.status.class">{{company.status.label}}</span>
                        <vs-dropdown-menu style="z-index: 200001">
                            <span class="cursor-pointer chip"  @click.stop v-for="(status, index) in statusList" :key="index">
                                <span class="point" :class="'bg-'+status.class"></span>
                                <span class="label" :class="'text-'+status.class">{{status.label}}</span>
                            </span>
                        </vs-dropdown-menu>
                    </vs-dropdown> -->
                </div>
                <b-button-group>
                    <b-button
                    v-if="archived"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="deleteCompanyLocal(company.id, company.name)"
                    >
                        <feather-icon icon="TrashIcon" />  Supprimer définitivement
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-danger"
                      class="btn-icon"
                      @click="archiveCompanyLocal(company.id, company.name)"
                    >
                        <feather-icon icon="ArchiveIcon" /> 
                    </b-button>
                </b-button-group>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-content" v-if="company.addresses">
                <div v-show="company.email || company.phoneNumber">
                    <h4 class="mb-1 text-primary"><feather-icon icon="PhoneCallIcon" /> Coordonnées</h4>
                    <ul class="listTab">
                        <li v-show="company.email">
                            <span>Adresse e-mail</span>
                            <span>{{company.email}}</span>
                        </li>
                        <li v-show="company.phoneNumber">
                            <span>Téléphone</span>
                            <span>{{company.phoneNumber}}</span>
                        </li>
                        <li v-show="company.secondaryPhoneNumber">
                            <span>Mobile</span>
                            <span>{{company.secondaryPhoneNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
                <div v-show="company.addresses.length>0">
                    <h4 class="mb-1 text-primary" ><feather-icon icon="MapPinIcon" /> Adresse{{company.addresses.length>1 ? 's' : ''}}</h4>
                    <p v-for="(address, index) of company.addresses" :key="index" class="paraphbox">
                      <span v-show="address.address">{{address.address}}<br></span>
                      <span v-show="address.addressComplement">{{address.addressComplement}}<br></span>
                      <span v-show="address.zipCode || address.city ">{{address.zipCode}} {{address.city}}<br></span>
                      <span v-show="address.country ">{{address.country}}</span>
                    </p>
                    <vs-divider /> 
                </div>
                <div v-show="company.siret || company.tvaNumber || company.companyAccount">
                    <h4 class="mb-1 text-primary"><feather-icon icon="ClipboardIcon" /> Juridique</h4>
                    <ul class="listTab">
                        <li v-if="company.siret">
                            <span>N° Siret</span>
                            <span>{{company.siret}}</span>
                        </li>
                        <li v-if="company.tvaNumber">
                            <span>N° TVA intra</span>
                            <span>{{company.tvaNumber}}</span>
                        </li>
                        <li v-show="company.companyAccount">
                            <span>N° Compte Client</span>
                            <span>{{company.companyAccount}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>

                <div v-if="company.contactIds && company.contactIds.length>0">
                    <h4 class="mb-1 text-primary d-flex justify-content-between" >
                      <span>
                        <feather-icon icon="ChevronRightIcon" /> {{company.contactIds.length>1 ? 'Contacts liés' : 'Contact lié' }}
                      </span>
                      <span></span>
                    </h4>
                    <div v-for="(contact, index) in company.contactIds" :key="index">
                        <h5 class="mb-1 text-primary d-flex justify-content-between" >
                          <span>
                            {{(contact.civility==0 ? 'M. ' : contact.civility==1 ? 'Mme ' : '')+ contact.firstName+ ' ' + contact.lastName}}
                          </span>
                          <span>
                           
                            <feather-icon icon="ExternalLinkIcon" class="cursor-pointer" @click="editContact(contact)" />
                            <!-- @click="editCompany(affair.company.id)" -->
                          </span>
                        </h5>
                        <ul class="listTab">
                            <li v-if="contact.email">
                                <span>Email</span>
                                <span>{{contact.email}}</span>
                            </li>
                            <li v-if="contact.phoneNumber">
                                <span>Téléphone</span>
                                <span>{{contact.phoneNumber}}</span>
                            </li>
                            <li v-if="contact.secondaryPhoneNumber">
                                <span>Tél secondaire</span>
                                <span>{{contact.secondaryPhoneNumber}}</span>
                            </li>
                        </ul>

                        <vs-divider />
                      </div> 
                </div>
            </div>
        </div>
    </vs-sidebar>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
  data () {
    return {
      archived:false,
      company:{},
      companyType:{}
    }
  },
  computed: {
    ...mapGetters([
      "isUpdatingCompany",
      "compagniesList",
      "isLoadingCompany",
      "isDeletingCompany",
      "isOpenSidebarCompany",
    ]),
    active: {
      get ()    { return this.isOpenSidebarCompany },
      set (val) { this.$store.commit('SET_IS_OPEN_SIDEBAR_COMPANY', val) }
    },
  },
  methods: {
    ...mapActions(["updateCompany", "deleteCompany", "isArchivingCompany", "archiveCompany", "restoreCompany", "fetchCompany", "fetchCompanyArchived"]),
    getCompanyArchivedDetails(id, archived) {
      this.archived=archived
      if (id>0) {
        this.fetchCompanyArchived(id).then(res => {
          this.company = res
          this.company.companyTypeId=res.companyType.id 
          this.company.contactIds=res.contacts
          this.companyType=res.companyType
          delete this.company.companyType
          delete this.company.contacts
        })
      } else {
        this.company={}
      }
    },
    getCompanyDetails(id) {
      this.archived=false
      if (id>0) {
        this.fetchCompany(id).then(res => {
          this.company = res
          this.company.companyTypeId=res.companyType.id 
          this.company.contactIds=res.contacts
          this.companyType=res.companyType
          delete this.company.companyType
          delete this.company.contacts
        })
      } else {
        this.company={}
      }
    },
    editCompany(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_COMPANY', false) 
        this.$router.push({ name: 'edit-company', params: { id: id, title: "Edit : "+ (this.company.name ? this.company.name : this.company.firstName + ' ' + this.company.lastName), tips:"Editer l\'entreprise : "+(this.company.name ? this.company.name : this.company.firstName + ' ' + this.company.lastName) } })
    },
    changeCompanyColor(){

      let dataChange=JSON.parse(JSON.stringify(this.company))
      dataChange.companyTypeId=this.company.companyTypeId
      dataChange.contactIds=this.company.contactIds ? this.company.contactIds.map(elem => { return elem.id  }) : null
      delete dataChange.companyType
      delete dataChange.contacts

      this.updateCompany(dataChange)
    },
    archiveCompanyLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver le '+this.companyType.label?.toLowerCase() +' "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveCompany(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_COMPANY', false)
          }
        })
    },
    restoreCompanyLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.', {
          title:
            'Êtes-vous sûr de vouloir restaurer le '+this.companyType.label?.toLowerCase() +' "' + label + '" ?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Restaurer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.restoreCompany(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_COMPANY', false)
          }
        })
    },
    deleteCompanyLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer le '+this.companyType.label?.toLowerCase() +' "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteCompany(id)
            this.$store.commit('SET_IS_OPEN_SIDEBAR_COMPANY', false)
          }
        })
    },
    editContact(contact){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_COMPANY', false) 
        this.$router.push({ name: 'edit-contact', params: { id: contact.id, title: "Edit : "+ contact.firstName + ' ' + contact.lastName, tips:"Editer le contact : "+contact.firstName + ' ' + contact.lastName } })
    },
  },
  components: {
    VuePerfectScrollbar,
    VSwatches
  }
}
</script>


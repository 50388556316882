<template>
  <div>
    <b-row class="m-0">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }} <span v-show="archive" class="text-danger">&nbsp;(archive)</span>
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchCompany"
            v-model="searchCompany"
            :placeholder="'Rechercher'+($route.params.name ? ($route.params.name[$route.params.name.length-1] =='s' ? ' un '+$route.params.name.substring(0, $route.params.name.length - 1) : ' un '+$route.params.name) : ' une entrepise')"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div> 
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="newThird('')"
            class="px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            Nouveau 
          </b-button>
          <vs-dropdown class="cursor-pointer">
            <div>
              <b-button
                variant="outline-primary"
                class="px-1"
              >
                  <feather-icon icon="SettingsIcon" />
                  <!-- Paramètres -->
              </b-button>
            </div>
            <vs-dropdown-menu class="w-full">
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="archive=!archive">{{ !archive ? 'Voir les '+$route.params.name+' archivés' : 'Voir les '+$route.params.name+' actifs' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="togglePanelColumn">{{this.gridOptions.sideBar == null? 'Gérer les colonnes' : 'Masquer la gestion des colonnes' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingCompaniesList || isLoadingCompaniesListArchived">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        {{archive ? 'Chargement des archives en cours...' : 'Chargement en cours de la liste des '+$route.meta.title+'...'}}
      </div>
      <ag-grid-vue v-else style="width: 100%; height: 100%;"
          class="ag-theme-material"
          :class="archive ? 'ag-archived' : ''"
          :rowData="gridData"
          :key="archive+filter"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked">
      </ag-grid-vue>
    </div>

    <sidebar-company :ref="'sidebarCompany'+$route.params.id" :key="'sidebarCompany'+$route.params.id" v-if="sidebar" />
    <!--  -->
    <!-- <sidebar-company :ref="'sidebarCompany'+$route.params.id" :key="'sidebarCompany'+$route.params.id" :id="rowId" :type="archive" /> -->
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import { agGridLocaleFR } from "@/data/agGridLocaleFR"

import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'

import CellRendererColors from '@/components/directory/company/CellRendererColors'

import SidebarCompany from '@/components/directory/company/SidebarCompany'

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,
    AgGridVue,
    CellRendererColors,
    SidebarCompany
  },
  directives: {
    Ripple
  },
  data () {
    return {
      archive:false,
      filter: false,
      sidebar:false,
      rowId:"",
      activeSidebarCompany:false,
      gridOptions:{
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination:true,
        paginationPageSize:20,
      },
      gridApi: null,
      columnApi: null,
      searchCompany: ''
    }
  },
  computed: {
    ...mapGetters([
      'companiesList',
      'companiesListArchived',
      'isLoadingCompaniesList',
      'isLoadingCompaniesListArchived',
      'isOpenSidebarCompany',
    ]),
    gridData () {
      if(this.archive){
        return this.$store.getters.companiesListArchived.filter(company => company.companyType.id==this.$route.params.id)
      }else{
        return this.$store.getters.companiesList.filter(company => company.companyType.id==this.$route.params.id)
      }
    }
  },
  watch: { 
    '$route' (to, from){
      // A chaque changement de route on delete le composant sidebar pour qu'il en existe un seul à chaque clic sur une ligne
      this.sidebar=false
    }
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      { field: 'color', headerName: 'Couleur', resizable:true,  sortable: true, maxWidth: 120, cellRenderer: 'CellRendererColors' },
      { field: 'name', headerName: 'Nom', lockVisible:true,  sortable: true, minWidth: 220, cellRenderer: 
        params => { 
          return params.data.isParticular == false ? params.data.name : params.data.lastName+' '+params.data.firstName  }
      },
      { field: 'civility', headerName: 'Civilité', initialHide:true,  sortable: true, headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, cellRenderer: 
        params => { return params.value == 0 ? 'M.' : params.value == 1 ? 'Mme.' : 'N.C'  }
      },
      { field: 'lastName', headerName: 'Nom', initialHide:true, resizable:true,  sortable: true, minWidth: 160},
      { field: 'firstName', headerName: 'Prénom', initialHide:true, resizable:true,  sortable: true, minWidth: 160},
      { field: 'email', headerName: 'Adresse email', resizable:true, sortable: true, minWidth: 160},
      { field: 'phoneNumber', headerName: 'Téléphone', resizable:true,  sortable: true, minWidth: 160},
      { field: 'secondaryPhoneNumber', headerName: 'Tél secondaire', resizable:true,  sortable: true, minWidth: 160},
      { field: 'siret', headerName: 'N° Siret', initialHide:true, resizable:true,  sortable: true, minWidth: 160},
      { field: 'tvaNumber', headerName: 'N° TVA intra', initialHide:true, resizable:true,  sortable: true, minWidth: 160},
      { field: 'companyAccount', headerName: 'N° Compte Client', initialHide:true, resizable:true,  sortable: true, minWidth: 160},
      { field: 'notes', headerName: 'Commentaire', initialHide:true, resizable:true,  sortable: true, minWidth: 260},
    ];
  },
  methods: {
    ...mapActions([ 
      'fetchCompaniesList',
      'fetchCompaniesListArchived',
      'deleteCompany'
    ]),

    // Gestion d'AgGrid
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event){
      this.rowId=event.data.id
      this.sidebar=true
      // On appel la fonction du composant qui va rechercher les détails d'une entreprise dans le bas 
      // Après avoir instancié le composant SideBar
      this.$nextTick(() => {
        if(this.archive){
          this.$refs['sidebarCompany'+this.$route.params.id].getCompanyArchivedDetails(event.data.id, 1)
        }else{
          this.$refs['sidebarCompany'+this.$route.params.id].getCompanyDetails(event.data.id)
        }
        this.$store.commit('SET_IS_OPEN_SIDEBAR_COMPANY', true)
      });
      
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.searchCompany
      );
    },
    togglePanelColumn(){
      if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Colonnes',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }
        this.filter=true;
      }else{
        this.gridOptions.sideBar= null
        this.filter=false;
      }
    },
    exportDataXLS(){
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV(){
      this.gridApi.exportDataAsCsv();
    },
    newThird (id) {
      this.$router.push({ path: '/directory/new-third/' + this.$route.params.name.toLowerCase() + '/' + this.$route.params.id })
    },
  },
}
</script>
